<template>
<div>
  留言和评论
</div>
</template>


<script>
export default {
  name : 'MessageAndComment'
}
</script>


<style scoped>

</style>